<template>
  <v-container
    id="container"
    fluid
    class="d-flex align-center justify-center"
  >
    <v-row class="d-flex align-center">
      <v-col
        offset="1"
        offset-sm="2"
        offset-md="2"
        offset-lg="3"
        cols="10"
        sm="8"
        md="8"
        lg="6"
      >
        <template v-if="invite">
          <ValidationObserver
            id="inviteObserver"
            ref="inviteObserver"
            v-slot="{ handleSubmit }"
          >
            <v-form
              id="form"
              ref="form"
              role="registerForm"
              @submit.prevent="handleSubmit(completeRegistration)"
              @reset="resetForm"
            >
              <v-row
                class="text-h4 font-weight-bold mb-8"
                justify="center"
              >
                {{ $t("completeYourRegistration") }}
              </v-row>
              <v-row
                class="text-subtitle-1 mb-8"
                justify="center"
              >
                {{ $t("inviteSetupAccount", { name: invite.senderName }) }}
              </v-row>
              <v-row class="mb-6">
                <div
                  class="flex flex-column align-start rounded-lg py-2 px-3"
                  style="background-color: #F9F9FB;"
                >
                  <label
                    for="organization"
                    class="text-caption"
                  >{{ $t("org") }}</label>
                  <div class="flex-grow-1" />
                  <span class="font-weight-bold">{{ invite.organization }}</span>
                </div>
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("username") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="username"
                  v-model="user.handle"
                  name="username"
                  :rules="usernameValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('username') })"
                  height="38"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("firstName") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="firstname"
                  v-model="user.firstName"
                  name="First name"
                  :rules="firstNameValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('first name') })"
                  height="45"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("lastName") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="lastname"
                  v-model="user.lastName"
                  name="Last name"
                  :rules="lastNameValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('surname') })"
                  height="45"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("email") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="email"
                  v-model="user.email"
                  name="email"
                  :rules="emailValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('email') })"
                  height="45"
                  background-color="#F9F9FB"
                  class="field-theme"
                  disabled
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("password") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="password"
                  v-model="user.password"
                  name="password"
                  :rules="passwordValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('password') })"
                  height="45"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                  :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="visiblePassword ? 'text' : 'password'"
                  @click:append="visiblePassword = !visiblePassword"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("reEnterPassword") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="confirmPassword"
                  v-model="confirmPassword"
                  :placeholder="$t('reEnterPassword')"
                  height="45"
                  :rules="[passwordConfirmationRule]"
                  :append-icon="visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="visibleConfirmPassword ? 'text' : 'password'"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                  @click:append="visibleConfirmPassword = !visibleConfirmPassword"
                />
              </v-row>
      
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-btn
                    id="signup"
                    block
                    color="primary"
                    type="submit"
                    :depressed="true"
                    class="btn-theme"
                    height="40"
                    :loading="signupBtnLoading"
                    :disabled="!isFormValid || signupBtnLoading"
                    :class="{ 'btn-loading-opacity': signupBtnLoading }"
                  >
                    {{ $t("completeRegistration") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    block
                    class="btn-theme"
                    color="error"
                    bg-color="error"
                    :depressed="true"
                    @click="declineInvite"
                  >
                    {{ $t("decline") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </template>
        <template v-else>
          <ValidationObserver
            id="observer"
            ref="observer"
            v-slot="{ handleSubmit }"
          >
            <v-form
              id="form"
              ref="form"
              role="registerForm"
              @submit.prevent="handleSubmit(saveUser)"
              @reset="resetForm"
            >
              <v-row
                class="fs-24px fw-semibold mb-8"
                justify="center"
              >
                {{ $t("signUpHeader") }}
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("username") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="username"
                  v-model="user.handle"
                  name="username"
                  :rules="usernameValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('username') })"
                  height="38"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("firstName") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="firstname"
                  v-model="user.firstName"
                  name="First name"
                  :rules="firstNameValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('name') })"
                  height="38"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("lastName") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="lastname"
                  v-model="user.lastName"
                  name="Last name"
                  :rules="lastNameValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('surname') })"
                  height="38"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("email") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="email"
                  v-model="user.email"
                  name="email"
                  :rules="emailValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('email') })"
                  height="38"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                />
              </v-row>
              <v-row class="d-flex flex-column">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("password") }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
                <v-text-field
                  id="password"
                  v-model="user.password"
                  name="password"
                  :rules="passwordValidation"
                  :placeholder="$t('inputPlaceholder', { field: $t('password') })"
                  height="38"
                  background-color="#F9F9FB"
                  class="field-theme"
                  :disabled="signupBtnLoading"
                  :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="visiblePassword ? 'text' : 'password'"
                  @click:append="visiblePassword = !visiblePassword"
                />
              </v-row>
              <v-row class="my-8">
                <v-btn
                  id="signup"
                  block
                  color="primary"
                  type="submit"
                  :depressed="true"
                  class="btn-theme"
                  height="40"
                  :loading="signupBtnLoading"
                  :class="{ 'btn-loading-opacity': signupBtnLoading }"
                >
                  {{ $t("signUp") }}
                </v-btn>
              </v-row>
              <v-row
                class="text-theme-secondary"
                justify="center"
              >
                {{ $t("alreadyHaveAccount") }}
                <router-link
                  id="sign-up-link"
                  to="/login"
                  class="text-decoration-none fw-semibold fs-14 ml-6"
                >
                  {{ $t("logintoyouraccount") }}
                </router-link>
              </v-row>
            </v-form>
          </ValidationObserver>
        </template>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDeclineDialog"
      max-width="480px"
      style="z-index: 1000;"
    >
      <v-card class="pa-6">
        <v-card-title
          class="font-weight-bold  text-start d-flex align-start justify-space-between flex-nowrap"
        >
          <div
            class="pr-8 dialogTitle"
          >
            {{ step === 1 ? $t('areYouSureDecline') : $t('whyDeclineInvitation') }}
          </div>
          <v-icon color="black">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text
          v-if="step === 1"
          class="mb-4 text-start"
        >
          {{ $t('sureDeclineInvitation', { name: invite?.senderName }) }}
        </v-card-text>
        <v-card-text
          v-if="step === 2"
          class="mb-4 text-start"
        >
          <v-radio-group v-model="declineReason">
            <v-radio
              :label="$t('declineReasons.alreadyPartOfOrganization')"
              value="alreadyPartOfOrganization"
            />
            <v-radio
              :label="$t('declineReasons.notInterestedInJoining')"
              value="notInterestedInJoining"
            />
            <v-radio
              :label="$t('declineReasons.preferDifferentPlatform')"
              value="preferDifferentPlatform"
            />
            <v-radio
              :label="$t('declineReasons.other')"
              value="other"
            />
          </v-radio-group>
          <v-textarea
            v-if="declineReason === 'other'"
            v-model="otherReason"
            variant="solo-filled"
            :label="$t('declineReasons.specifyReason')"
            :placeholder="$t('declineReasons.specifyReasonPlaceholder')"
            style="background-color: #F9F9FB; border-radius: 8px;"
            class="pa-2"
          />
        </v-card-text>
        <v-card-actions class="flex">
          <v-spacer />
          <v-btn
            color="grey lighten-1"
            class="btn-theme "

            @click="cancelDecline"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="step === 1"
            color="error"
            class="btn-theme"
            @click="step = 2"
          >
            {{ $t('decline') }}
          </v-btn>
          <v-btn
            v-if="step === 2"
            color="primary"
            class="btn-theme"
            @click="confirmDecline"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import makeAuthService from "@/services/api/auth";
import makeOrgService from "@/services/api/org";
import { createNamespacedHelpers } from "vuex";
import { handleDuplicateMixin } from "@/mixins/handleDuplicate.js";
import { emailValidationRules, passwordValidationRules, 
        firstNameValidation, lastNameValidation, usernameValidation } from "@/utils/validation";
import { createSelectedHandle } from '@/utils/accountUtils';
const { mapMutations, mapActions } = createNamespacedHelpers("user");
import { showAuthErrorToast } from '@/utils/toast';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  mixins: [handleDuplicateMixin],
  data()
  {
    return {
      user: {
        handle: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
      visiblePassword: false,
      visibleConfirmPassword: false,
      confirmPassword: "",
      invite: null,
      signupBtnLoading: false,
      showDeclineDialog: false,
      step: 1,
      declineReason: null,
      otherReason: ''
    };
  },
  computed: {
    emailValidation()
    {
      return emailValidationRules(this);
    },
    firstNameValidation()
    {
      return firstNameValidation(this);
    },
    lastNameValidation()
    {
      return lastNameValidation(this);
    },
    passwordValidation()
    {
      return passwordValidationRules(this);
    },
    usernameValidation()
    {
      return usernameValidation(this);
    },
    disableEmail()
    {
      return !!this.invite;
    },
    passwordConfirmationRule() {
      return () => (this.user.password === this.confirmPassword) || this.$t('passwordsMustMatch');
    },
    isFormValid() {
      return this.user.handle &&
             this.user.firstName &&
             this.user.lastName &&
             this.user.email &&
             this.user.password &&
             this.confirmPassword &&
             this.user.password === this.confirmPassword 
    },
  },
  watch: {
    'user.handle': {
      handler: 'usernameInUse',
      immediate: true,
    },
  },
  mounted()
  {
    this.checkForInvite(this.$route.query);
  },

  methods: {
    ...mapMutations(["setUser"]),
    ...mapActions(["initSession", "setCurrentAccount"]),
    validate () {
        return this.$refs.form.validate()
      },
      reset () {
        return this.$refs.form.reset()
      },
      resetValidation () {
        return this.$refs.form.resetValidation()
      },
    saveUser()
    {
      if(this.validate()){
      this.signupBtnLoading = true;
      const authService = new makeAuthService(this.$api);
      authService
        .register(this.user)
        .then(async (response) =>
        {
          const user = response.data.user;
          this.initSession({
            user,
            currentAccount: {
              handle: user.handle,
              type: "user",
              name: `${user.firstName} ${user.lastName}`,
              roleName: "owner"
            }
          })

          if (this.invite) {
            return this.$router.push({ name: 'GetInvite', params: this.invite });
          }

          this.$router.push('/setup');
        })
        .catch((error) =>
        {
          let errorMessage;
          if (Array.isArray(error.response.data.error)) {
            errorMessage = error.response.data.error.join(' ');
          } else {
            errorMessage = error.response.data.error;
          }
          showAuthErrorToast(this.$swal, errorMessage);
        }).finally(() => {
          this.signupBtnLoading = false;
        });
      }
    },
    resetForm()
    {
      this.user = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      };
      this.$nextTick(() =>
      {
        if (this.invite) {
              this.$refs.inviteObserver.reset();
        } else {
              this.$refs.observer.reset();
        }
    

      });
    },
    checkForInvite(params) {
      if (!params?.token || !params.org) return;

      const invite = { handle: params.org, token: params.token };
      makeOrgService(this.$api)
        .validateInvite(invite)
        .then((response) => {
          this.invite = {
            ...invite,
            organization: response.data.name,
            senderName: `${response.data.sender.firstName} ${response.data.sender.lastName}`,
          };
          this.user.email = response.data.email;
        })
        .catch((error) => {
          // error validating invite
          showAuthErrorToast(this.$swal, this.$t('invalidInvite'));
        });
    },
    async completeRegistration() {
      if (!this.$refs.form || typeof this.$refs.form.validate !== 'function') {
        console.error('Form reference is not available');
        showAuthErrorToast(this.$swal, this.$t('formValidationError'));
        return;
      }

      if (!this.$refs.form.validate()) {
        return;
      }

      this.signupBtnLoading = true;

      try {
        const authService = new makeAuthService(this.$api);
        const response = await authService.register(this.user);
        
        if (response && response.data && response.data.user) {
          const user = response.data.user;

          await this.initSession({
            user,
            currentAccount: {
              handle: user.handle,
              type: "user",
              name: `${user.firstName} ${user.lastName}`,
              roleName: "owner"
            }
          });

          if (this.invite) {
            await this.handleInvite(true);
          } else {
            this.$router.push('/setup');
          }
        } else {
          throw new Error(this.$t('registrationFailed'));
        }
      } catch (error) {
        let errorMessage = Array.isArray(error.response?.data?.error)
          ? error.response.data.error.join(' ')
          : error.response?.data?.error || error.message;
        showAuthErrorToast(this.$swal, errorMessage);
      } finally {
        this.signupBtnLoading = false;
      }
    },
    async handleInvite(accept, reason) {
      const orgService = makeOrgService(this.$api);
      try {
        if (accept) {
          const response = await orgService.acceptInvite({
            handle: this.invite.handle,
            token: this.invite.token,
          });

          if (response.status === 200) {
            const selectedHandle = createSelectedHandle(
              this.$route.query.org,
              this.invite.organization,
              'org',
              'member'
            );

            this.setCurrentAccount(selectedHandle);
            showSuccessToast(this.$swal, 'invitationUpdate', { action: 'Accepted' });
            // Updated router navigation
            this.$router.replace({ name: 'ProjectsView', params: { handle: this.invite.handle } });
          }
        } else {
          await orgService.declineInvite({
            handle: this.invite.handle,
            token: this.invite.token,
            reason: reason,
          });
          showErrorToast(this.$swal, 'invitationUpdate', { action: 'Declined' });
          this.$router.push('/');
        }
      } catch (error) {
        showAuthErrorToast(this.$swal, error.response?.data?.error || error.message);
      }
    },
    declineInvite() {
      this.showDeclineDialog = true;
    },
    async confirmDecline() {
      const reason = this.declineReason === 'other' ? this.otherReason : this.declineReason;
      await this.handleInvite(false, reason);
      this.showDeclineDialog = false;
    },
    cancelDecline() {
      this.showDeclineDialog = false;
    },
  },
};
</script>


<style scoped>
.dialogTitle{
font-family: Inter;
font-size: 22px;
font-weight: 700;
line-height: 28px;
text-align: left;
max-width: 320px;
word-break: keep-all;
}
.btn-theme{
  width: 50%;
}
</style>

